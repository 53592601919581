import axios from "axios";
import { useEffect, useState } from "react"
import { Card, CardHeader, CardText, CardTitle, Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";

export default function ViewAppointment(){
    const [appointment, setAppointment] = useState([]);
    const {appointmentId} = useParams();

    useEffect(() => {
        axios.get(`https://project01-n0pn.onrender.com/api/appointment/getappointment/${appointmentId}`).then((response) => {
            setAppointment(response.data);
        })
})

    return(
        <>
        <Card className="card-container">
        <h1 className="header">View Appointment</h1>
        <Row>
        <Col>
        <CardHeader>
            <CardTitle>Doctor Name</CardTitle>
            <CardText>{appointment.doctor_name}</CardText>
            </CardHeader>
        <br/>
        <CardHeader>
        <CardTitle>Patient NIC</CardTitle> 
            <CardText>{appointment.patinet_nic}</CardText>
            </CardHeader>
        <br/>
        <CardHeader>
        <CardTitle>Appointment Date</CardTitle>
        <CardText>{appointment.appointment_date}</CardText>
        </CardHeader>
        <br/>
        <CardHeader>
        <CardTitle>Appointment Status</CardTitle>
        <CardText>{appointment.appointment_status}</CardText>
        </CardHeader>
        <br/>
        </Col>
        </Row>
        </Card>
        </>
    )
}
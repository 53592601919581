// ViewAllPatients.jsx
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Card, Table } from 'react-bootstrap';
import Pagination from '../../Components/Features/Pagination/Pagination';
import SearchBar from '../../Components/Features/SearchBar/SearchBar';
import './ViewAllPatients.css';
import { Link } from 'react-router-dom';
import moment from 'moment';

export default function ViewAllPatients() {
  const [patients, setPatients] = useState([]);
  const [filteredPatients, setFilteredPatients] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [patientsPerPage] = useState(5);

  useEffect(() => {
    axios.get('https://project01-n0pn.onrender.com/api/patient/getallpatients').then((response) => {
      setPatients(response.data);
      setFilteredPatients(response.data); // Initialize filteredPatients with all patients
    });
  }, []);

  // Calculate the number of pages
  const totalPages = Math.ceil(filteredPatients.length / patientsPerPage);

  // Get current patients data for the page
  const indexOfLastPatient = currentPage * patientsPerPage;
  const indexOfFirstPatient = indexOfLastPatient - patientsPerPage;
  const currentPatients = filteredPatients.slice(indexOfFirstPatient, indexOfLastPatient);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleDelete = (patientId) => {
    axios.delete(`https://project01-n0pn.onrender.com/api/patient/deletepatient/${patientId}`).then((response) => {
      alert("Deleted");
      window.location.reload();
    })
  }

  const handleSearch = (query) => {
    setCurrentPage(1); // Reset to first page on new search
    if (query) {
      const lowercasedQuery = query.toLowerCase();
      const filteredData = patients.filter(patient =>
        Object.values(patient).some(value =>
          value.toString().toLowerCase().includes(lowercasedQuery)
        )
      );
      setFilteredPatients(filteredData);
    } else {
      setFilteredPatients(patients);
    }
  };

  return (
    <>
    <Card className='card-container'>
      <h1 className="header">Patients Management</h1>
      <Button className="report-button" as={Link} to={'/report-patient'}>Generate Report</Button>
      <SearchBar onSearch={handleSearch} />
      <Table responsive className="table">
        <thead>
          <tr>
            <th>NIC</th>
            <th>Name</th>
            <th>Age</th>
            <th>Gender</th>
            <th>Contact Number</th>
            <th>Created At</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentPatients.map((patient) => (
            <tr key={patient.patient_id}>
              <td>{patient.patient_nic}</td>
              <td>{patient.name}</td>
              <td>{patient.age}</td>
              <td>{patient.gender}</td>
              <td>{patient.contact_number}</td>
              <td>{moment(patient.createdAtPatient).format("MMMM Do YYYY, h:mm:ss a")}</td>
              <td>
              <Button variant="secondary" as={Link} to={`/view-patient/${patient.patient_id}`}>View</Button> {""}
                <Button variant="warning" as={Link} to={`/update-patient/${patient.patient_id}`}>Update</Button> {""}
                <Button variant="danger" onClick={() => handleDelete(patient.patient_id)}>Delete</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
      </Card>
    </>
  );
}
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Card, Col, Row, Form, Button, Toast, ToastContainer, Container } from 'react-bootstrap'; // Import Bootstrap components
import image1 from '../Images/logo.png';

const SignIn = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showToast, setShowToast] = useState(false); // State for controlling the visibility of the toast
    const [message, setMessage] = useState('');
    const navigate = useNavigate();
    const [toastVariant, setToastVariant] = useState('');

    const handleSignIn = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post('https://project01-n0pn.onrender.com/api/staff/signin', { username, password });
            const userId = response.data.id; // Assuming the response contains the userId
            setMessage('Successfully signed in');
            setShowToast(true); // Show toast upon successful sign-in
            setToastVariant("primary");
            navigate('/home', { state: { userId } });
        } catch (error) {
            if (error.response && error.response.status === 401) {
                setMessage('Invalid username or password');
                setShowToast(true); // Show toast for invalid credentials
            } else {
                setMessage('An error occurred. Please try again.');
            }
        }
    };

    return (
        <div style={{marginLeft: "-250px"}}>
            <Container>
            <Card>
                <Card.Body>
                    <Row className="align-items-center justify-content-center">
                        <Col>
                            <img src={image1} style={{width: "80%"}} alt='logo'/>
                        </Col>
                        <Col>
                        <h1 className='header'>Sign In</h1>
                            <Form onSubmit={handleSignIn}>
                                <Form.Group controlId="formUsername">
                                    <Form.Control 
                                        type="text" 
                                        value={username} 
                                        onChange={(e) => setUsername(e.target.value)}
                                        placeholder='Username'
                                        required 
                                    />
                                </Form.Group>
                                <br/>
                                <Form.Group controlId="formPassword">
                                    <Form.Control 
                                        type="password" 
                                        value={password} 
                                        onChange={(e) => setPassword(e.target.value)}
                                        placeholder='Password'
                                        required 
                                    />
                                </Form.Group>
                                <br/>
                                <Button variant="primary" type="submit" className="w-100">
                                    Sign In
                                </Button>
                            </Form>
                            <br/>
                            <a href='/signup' style={{textDecoration: "none"}}>Don't have an account? Sign Up</a>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>

            {/* Toast container */}
            <ToastContainer position="top-end" style={{marginTop: "25px"}}>
            <Toast onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide>
    <Toast.Body style={{ backgroundColor: toastVariant === "success" ? "#28a745" : "#dc3545" }}>
        {message}
    </Toast.Body>
</Toast>
            </ToastContainer>
            </Container>
        </div>
    );
};

export default SignIn;
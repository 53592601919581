// SearchBar.jsx
import React from 'react';
import { Form, FormControl } from 'react-bootstrap';

const SearchBar = ({ onSearch }) => {
  const handleInputChange = (event) => {
    const query = event.target.value;
    onSearch(query);
  };

  return (
    <Form className="search">
      <FormControl
        type="search"
        placeholder="Searching"
        className="me-2"
        aria-label="Search"
        onChange={handleInputChange}
      />
    </Form>
  );
};

export default SearchBar;
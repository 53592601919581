import React from 'react';
import './LoadingScreen.css'; // Create a CSS file for styling the splash screen
import image from '../../Images/logo_circle.png';

const LoadingScreen = () => {
    return (
        <div className="splash-screen">
            <img src={image} alt='logo' className="rotate-image" />
            <p>Loading....</p>
        </div>
    );
};

export default LoadingScreen;
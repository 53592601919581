import axios from "axios";
import { useEffect, useState } from "react"
import { Card, CardHeader, CardText, CardTitle, Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";

export default function ViewStaff(){
    const [staffUser, setStaffUser] = useState([]);
    const {userId} = useParams();

    useEffect(() => {
        axios.get(`https://project01-n0pn.onrender.com/api/staff/getuser/${userId}`).then((response) => {
            setStaffUser(response.data);
        })
})

    return(
        <>
        <Card className="card-container">
        <h1 className="header">View staff</h1>
        <Row>
        <Col>
        <CardHeader>
            <CardTitle>First Name</CardTitle>
            <CardText>{staffUser.first_name}</CardText>
            </CardHeader>
        <br/>
        <CardHeader>
        <CardTitle>Last Name</CardTitle> 
            <CardText>{staffUser.last_name}</CardText>
            </CardHeader>
        <br/>
        <CardHeader>
        <CardTitle>User Name</CardTitle>
        <CardText>{staffUser.username}</CardText>
        </CardHeader>
        <br/>
        </Col>
        <Col>
        <CardHeader>
        <CardTitle>Staff NIC</CardTitle> 
        <CardText>{staffUser.staff_nic}</CardText>
        </CardHeader>
        <br/>
        <CardHeader>
        <CardTitle>User Role</CardTitle>
        <CardText>{staffUser.user_role}</CardText>
        </CardHeader>
        <br/>
        <CardHeader>
        <CardTitle>Staff Status</CardTitle>
        <CardText>{staffUser.staff_status}</CardText>
        </CardHeader>
        </Col>
        <Col>
        <img src={staffUser.imageUrl} className="user-image" alt="staffuser"/>
        </Col>
        </Row>
        </Card>
        </>
    )
}
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table, Card, Button, Form, Row, Col } from 'react-bootstrap';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import Pagination from '../Features/Pagination/Pagination';
import moment from 'moment';

export default function AppointmentReport() {
  const [appointments, setAppointments] = useState([]);
  const [filteredAppointments, setFilteredAppointments] = useState([]);
  const [filters, setFilters] = useState({
    status: '',
    patientName: ''
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [appointmentsPerPage] = useState(5);

  useEffect(() => {
    axios.get('https://project01-n0pn.onrender.com/api/appointment/getallappointments')
      .then((response) => {
        setAppointments(response.data);
        setFilteredAppointments(response.data);
      })
      .catch((error) => {
        console.error('Error fetching appointment data:', error);
      });
  }, []);

  // Calculate the number of pages
  const totalPages = Math.ceil(filteredAppointments.length / appointmentsPerPage);

  // Get current appointments data for the page
  const indexOfLastAppointment = currentPage * appointmentsPerPage;
  const indexOfFirstAppointment = indexOfLastAppointment - appointmentsPerPage;
  const currentAppointments = filteredAppointments.slice(indexOfFirstAppointment, indexOfLastAppointment);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const downloadPDF = () => {
    const input = document.getElementById('appointment-report');
    html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF();
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf.save('appointment_report.pdf');
      })
      .catch((error) => {
        console.error('Error generating PDF:', error);
      });
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value
    });
  };

  const applyFilters = () => {
    let filteredData = appointments;

    if (filters.appointment_status) {
      filteredData = filteredData.filter(appointment => appointment.appointment_status.toLowerCase().includes(filters.appointment_status.toLowerCase()));
    }

    if (filters.patientName) {
      filteredData = filteredData.filter(appointment => appointment.patient_nic?.toLowerCase().includes(filters.patientName.toLowerCase()));
    }

    setFilteredAppointments(filteredData);
  };

  useEffect(() => {
    applyFilters();
  }, [filters]);

  return (
    <Card className='card-container'>
      <Card.Body>
        <Card.Title className='header'>Appointment Report</Card.Title>
        <Form>
          <Row>
            <Col>
          <Form.Group controlId="filterPatientName">
            <Form.Control
              type="text"
              placeholder="Enter patient NIC"
              name="patientName"
              value={filters.patientName}
              onChange={handleFilterChange}
            />
          </Form.Group>
          </Col>
          <Col>
          <Form.Group controlId="filterApointmentStatus">
            <Form.Control
              type="text"
              placeholder="Enter Appointment Status"
              name="appointment_status"
              value={filters.appointment_status}
              onChange={handleFilterChange}
            />
          </Form.Group>
          </Col>
          </Row>
        </Form>
        <Button variant="primary" onClick={downloadPDF} className="mt-3">Download as PDF</Button>
        <div id="appointment-report" className="mt-3">
          <Table responsive className="table">
            <thead>
              <tr>
              <th>Patient NIC</th>
              <th>Doctor Name</th>
              <th>Appointment Date</th>
              <th>Created At</th>
              <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {currentAppointments.map((appointment) => (
                <tr key={appointment.appointment_id}>
                  <td>{appointment.patient_nic}</td>
                <td>{appointment.doctor_name}</td>
                <td>{moment(appointment.appointment_date).format("MMMM Do YYYY, h:mm:ss a")}</td>
                <td>{moment(appointment.createdAtAppointment).format("MMMM Do YYYY, h:mm:ss a")}</td>
                <td>{appointment.appointment_status}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
        </div>
      </Card.Body>
    </Card>
  );
}
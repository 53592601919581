import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Form, FormGroup, FormLabel, FormControl, Container, Card, Row, Col, Toast, ToastContainer } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

export default function AddAppointment() {
  const [appointment, setAppointment] = useState({
    patient_id: '',
    doctor_name: '',
    appointment_date: ''
  });
  const [patients, setPatients] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const navigate = useNavigate();
  const [toastVariant, setToastVariant] = useState('');

  useEffect(() => {
    // Fetch patients and doctors when the component mounts
    axios.get('https://project01-n0pn.onrender.com/api/patient/getallpatients')
      .then((response) => {
        setPatients(response.data);
      })
      .catch((error) => {
        console.error('Error fetching patients:', error);
      });

    axios.get('https://project01-n0pn.onrender.com/api/staff/getallusers')
      .then((response) => {
        setDoctors(response.data);
      })
      .catch((error) => {
        console.error('Error fetching doctors:', error);
      });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAppointment((prevAppointment) => ({
      ...prevAppointment,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios.post('https://project01-n0pn.onrender.com/api/appointment/saveappointment', appointment)
      .then((response) => {
        setToastMessage("Appointment added successfully");
        setToastVariant('success');
        setShowToast(true);
        setTimeout(() => {
          setShowToast(false); // Hide the toast after 5 seconds
          navigate("/home"); // Navigate to home after 5 seconds
        }, 5000);
      })
      .catch((error) => {
        setToastMessage("Error adding appointment");
        setToastVariant('danger');
        setShowToast(true);
        console.error('Error adding appointment:', error);
      });
  };  

  // Get current date and time in the format yyyy-MM-ddTHH:mm
  const getCurrentDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, '0');
    const day = now.getDate().toString().padStart(2, '0');
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  return (
    <Container>
      <Card className='card-container'>
        <h1 className='header'>Add New Appointment</h1>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col>
              <FormGroup controlId="formPatientId">
                <FormLabel>Patient NIC</FormLabel>
                <FormControl
                  as="select"
                  name="patient_nic"
                  value={appointment.patient_nic}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select patient NIC</option>
                  {patients.map(patient => (
                    <option key={patient.patient_id} value={patient.patient_nic}>
                      {patient.patient_nic}
                    </option>
                  ))}
                </FormControl>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup controlId="formDoctorName">
                <FormLabel>Doctor Name</FormLabel>
                <FormControl
                  as="select"
                  name="doctor_name"
                  value={appointment.doctor_name}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select doctor name</option>
                  {doctors.map(doctor => (
                    <option key={doctor.user_id} value={doctor.user_name}>
                      {doctor.user_name}
                    </option>
                  ))}
                </FormControl>
              </FormGroup>
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <FormGroup controlId="formAppointmentDate">
                <FormLabel>Appointment Date and Time</FormLabel>
                <FormControl
                  type="datetime-local"
                  name="appointment_date"
                  value={appointment.appointment_date}
                  onChange={handleChange}
                  min={getCurrentDateTime()} // Set min attribute to current date and time
                  required
                />
              </FormGroup>
            </Col>
          </Row>
          <Button className="submit-button" variant="success" type="submit">
            Add Appointment
          </Button>
        </Form>
      </Card>
      <ToastContainer position="top-end" className="p-3" style={{marginTop:"35px"}}>
          <Toast onClose={() => setShowToast(false)} show={showToast} className='toast' bg={toastVariant} delay={3000} autohide>
            <Toast.Body>{toastMessage}</Toast.Body>
          </Toast>
        </ToastContainer>
    </Container>
  );
}
// ViewAllAppointments.jsx
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Card, Table } from 'react-bootstrap';
import Pagination from '../../Components/Features/Pagination/Pagination';
import SearchBar from '../../Components/Features/SearchBar/SearchBar';
import { Link } from 'react-router-dom';
import moment from "moment";

export default function ViewAllCheckups() {
  const [checkups, setCheckups] = useState([]);
  const [filteredAppointments, setFilteredAppointments] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [appointmentsPerPage] = useState(5);

  useEffect(() => {
    axios.get('https://project01-n0pn.onrender.com/api/checkup/getallcheckups').then((response) => {
      setCheckups(response.data);
      setFilteredAppointments(response.data);  // Initialize filteredAppointments with all appointments
    });
  }, []);

  // Calculate the number of pages
  const totalPages = Math.ceil(filteredAppointments.length / appointmentsPerPage);

  // Get current appointments data for the page
  const indexOfLastAppointment = currentPage * appointmentsPerPage;
  const indexOfFirstAppointment = indexOfLastAppointment - appointmentsPerPage;
  const currentAppointments = filteredAppointments.slice(indexOfFirstAppointment, indexOfLastAppointment);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleDelete = (checkupId) => {
    axios.delete(`https://project01-n0pn.onrender.com/api/checkup/deletecheckup/${checkupId}`).then((response) => {
      alert("Deleted");
      window.location.reload();
    })
  }

  const handleSearch = (query) => {
    setCurrentPage(1);  // Reset to first page on new search
    if (query) {
      const lowercasedQuery = query.toLowerCase();
      const filteredData = checkups.filter(appointment =>
        Object.values(appointment).some(value =>
          value.toString().toLowerCase().includes(lowercasedQuery)
        )
      );
      setFilteredAppointments(filteredData);
    } else {
      setFilteredAppointments(checkups);
    }
  };

  return (
    <>
    <Card className='card-container'>
      <h1 className="header">Checkup Management</h1>
      <Button className="report-button" as={Link} to={'/report-checkup'}>Generate Report</Button>
      <SearchBar onSearch={handleSearch} />
      <Table responsive className="table">
        <thead>
          <tr>
            <th>Patient NIC</th>
            <th>Doctor Username</th>
            <th>Created At</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentAppointments.map((checkup) => (
            <tr key={checkup.checkup_id}>
              <td>{checkup.patient_nic}</td>
              <td>{checkup.doctor_user_name}</td> 
              <td>{moment(checkup.createdAtCheckup).format("MMMM Do YYYY, h:mm:ss a")}</td>
              <td>
                  <Button variant="secondary" as={Link} to={`/view-checkup/${checkup.checkup_id}`}>View</Button> {""}
                <Button variant="warning" as={Link} to={`/update-checkup/${checkup.checkup_id}`}>Update</Button> {""}
                <Button variant="danger" onClick={() => {handleDelete(checkup.checkup_id)}}>Delete</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
      </Card>
    </>
  );
}
import React, { useState } from 'react';
import axios from 'axios';
import { Button, Form, FormGroup, FormLabel, FormControl, Container, Card, Row, Col, Toast, ToastContainer } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

export default function AddPatient() {
  const [patient, setPatient] = useState({
    name: '',
    address: '',
    age: '',
    gender: '',
    email: '',
    contact_number: '',
    patient_nic: '',
    patient_dob: ''
  });
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastVariant, setToastVariant] = useState('');
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPatient((prevPatient) => ({
      ...prevPatient,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios.post('https://project01-n0pn.onrender.com/api/patient/savepatient', patient)
      .then((response) => {
        setToastMessage("Patient added successfully");
        setToastVariant('success');
        setShowToast(true);
        setTimeout(() => {
          setShowToast(false); // Hide the toast after 5 seconds
          navigate("/home"); // Navigate to home after 5 seconds
        }, 5000);
      })
      .catch((error) => {
        setToastMessage("Error adding patient");
        setToastVariant('success');
        setShowToast(true);
        console.error('Error adding patient:', error);
      });
  };

  return (
    <Container>
      <Card className='card-container'>
        <h1 className='header'>Add New Patient</h1>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col>
              <FormGroup controlId="formName">
                <FormLabel>Name</FormLabel>
                <FormControl
                  type="text"
                  name="name"
                  value={patient.name}
                  onChange={handleChange}
                  placeholder="Enter name"
                  required
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup controlId="formAddress">
                <FormLabel>Address</FormLabel>
                <FormControl
                  type="text"
                  name="address"
                  value={patient.address}
                  onChange={handleChange}
                  placeholder="Enter address"
                  required
                />
              </FormGroup>
            </Col>
          </Row>
          <br />
          <Row>
          <Col>
              <FormGroup controlId="formPatientNIC">
                <FormLabel>NIC</FormLabel>
                <FormControl
                  type="text"
                  name="patient_nic"
                  value={patient.patient_nic}
                  onChange={handleChange}
                  placeholder="Enter NIC"
                  required
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup controlId="formAge">
                <FormLabel>Age</FormLabel>
                <FormControl
                  type="number"
                  name="age"
                  value={patient.age}
                  onChange={handleChange}
                  placeholder="Enter age"
                  required
                />
              </FormGroup>
            </Col>
          </Row>
          <br/>
          <Row>
          <Col>
              <FormGroup controlId="formGender">
                <FormLabel>Gender</FormLabel>
                <FormControl
                  as="select"
                  name="gender"
                  value={patient.gender}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </FormControl>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup controlId="formEmail">
                <FormLabel>Email</FormLabel>
                <FormControl
                  type="email"
                  name="email"
                  value={patient.email}
                  onChange={handleChange}
                  placeholder="Enter email"
                  required
                />
              </FormGroup>
            </Col>
          </Row>
          <br/>
          <Row>
          <Col>
              <FormGroup controlId="forPatientDOB">
                <FormLabel>Date of birth</FormLabel>
                <FormControl
                  type="date"
                  name="patient_dob"
                  value={patient.patient_dob}
                  onChange={handleChange}
                  placeholder="Enter date of birth"
                  required
                />
              </FormGroup>
            </Col>
          <Col>
              <FormGroup controlId="formContactNumber">
                <FormLabel>Contact Number</FormLabel>
                <FormControl
                  type="text"
                  name="contact_number"
                  value={patient.contact_number}
                  onChange={handleChange}
                  placeholder="Enter contact number"
                  required
                />
              </FormGroup>
            </Col>
          </Row>
          <Button className="submit-button" variant="success" type="submit">
            Add Patient
          </Button>
        </Form>
      </Card>
      <ToastContainer position="top-end" className="p-3" style={{marginTop:"35px"}}>
        <Toast onClose={() => setShowToast(false)} show={showToast} className='toast' bg={toastVariant} delay={3000} autohide>
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>
    </Container>
  );
}
import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import AddStaff from './Staff/AddStaff';
import Home from './Home';
import NavBar from '../Components/Features/NavBar/NavBar';
import Footer from '../Components/Features/Footer/Footer';
import ViewAllStaff from './Staff/ViewAllStaff';
import ViewAllPatients from './Patient/ViewAllPatients';
import ViewAllAppointments from './Appointment/ViewAllAppointments';
import UpdateStaff from './Staff/UpdateStaff';
import AddPatient from './Patient/AddPatient';
import AddAppointment from './Appointment/AddAppointment';
import UpdatePatient from './Patient/UpdatePatient';
import UpdateAppointment from './Appointment/UpdateAppointment';
import ViewStaff from './Staff/ViewStaff';
import ViewPatient from './Patient/ViewPatient';
import ViewAppointment from './Appointment/ViewAppointment';
import AddCheckup from './Checkup/AddCheckup';
import ViewAllCheckups from './Checkup/ViewAllCheckups';
import UpdateCheckup from './Checkup/UpdateCheckup';
import ViewCheckup from './Checkup/ViewCheckup';
import Sidebar from '../Components/Features/SideBar/Sidebar';
import LoadingScreen from './Features/LoadingScreen/LoadingScreen';
import StaffReport from './Staff/StaffReport';
import PatientReport from './Patient/PatientReport';
import AppointmentReport from './Appointment/AppointmentReport';
import CheckupReport from './Checkup/CheckupReport';
import SignIn from './User/SignIn';
import SignUp from './User/SignUp';

const MainLayout = ({ children }) => {
    const location = useLocation();
    const isAuthRoute = location.pathname === '/' || location.pathname === '/signup';

    return (
        <>
            {!isAuthRoute && <NavBar />}
            <div className="app-container">
                {!isAuthRoute && <Sidebar />}
                <div className="content-container">
                    {children}
                </div>
            </div>
            {!isAuthRoute && <Footer />}
        </>
    );
};

export default function AppRouter() {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Simulate loading time with setTimeout
        const timer = setTimeout(() => {
            setLoading(false);
        }, 2000);

        return () => clearTimeout(timer);
    }, []);

    return (
        <>
            {loading ? (
                <LoadingScreen />
            ) : (
                <Router>
                    <Routes>
                        <Route path="/" element={<MainLayout><SignIn /></MainLayout>} />
                        <Route path="/signup" element={<MainLayout><SignUp /></MainLayout>} />
                        <Route path="/home" element={<MainLayout><Home /></MainLayout>} />
                        <Route path="/add-staff" element={<MainLayout><AddStaff /></MainLayout>} />
                        <Route path="/view-all-staff" element={<MainLayout><ViewAllStaff /></MainLayout>} />
                        <Route path="/update-staff/:userId" element={<MainLayout><UpdateStaff /></MainLayout>} />
                        <Route path="/view-staff/:userId" element={<MainLayout><ViewStaff /></MainLayout>} />
                        <Route path="/report-staff" element={<MainLayout><StaffReport /></MainLayout>} />
                        <Route path="/add-patient" element={<MainLayout><AddPatient /></MainLayout>} />
                        <Route path="/view-all-patients" element={<MainLayout><ViewAllPatients /></MainLayout>} />
                        <Route path="/update-patient/:patientId" element={<MainLayout><UpdatePatient /></MainLayout>} />
                        <Route path="/view-patient/:patientId" element={<MainLayout><ViewPatient /></MainLayout>} />
                        <Route path="/report-patient" element={<MainLayout><PatientReport /></MainLayout>} />
                        <Route path="/add-appointment" element={<MainLayout><AddAppointment /></MainLayout>} />
                        <Route path="/view-all-appointments" element={<MainLayout><ViewAllAppointments /></MainLayout>} />
                        <Route path="/update-appointment/:appointmentId" element={<MainLayout><UpdateAppointment /></MainLayout>} />
                        <Route path="/view-appointment/:appointmentId" element={<MainLayout><ViewAppointment /></MainLayout>} />
                        <Route path="/report-appointment" element={<MainLayout><AppointmentReport /></MainLayout>} />
                        <Route path="/add-checkup" element={<MainLayout><AddCheckup /></MainLayout>} />
                        <Route path="/view-all-checkups" element={<MainLayout><ViewAllCheckups /></MainLayout>} />
                        <Route path="/update-checkup/:checkupId" element={<MainLayout><UpdateCheckup /></MainLayout>} />
                        <Route path="/view-checkup/:checkupId" element={<MainLayout><ViewCheckup /></MainLayout>} />
                        <Route path="/report-checkup" element={<MainLayout><CheckupReport /></MainLayout>} />
                    </Routes>
                </Router>
            )}
        </>
    );
}
import React, { useState } from 'react';
import axios from 'axios';
import { Button, Form, FormGroup, FormLabel, FormControl, Container, Card, Row, Col, Toast, ToastContainer } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

export default function AddStaff() {
  const [staff, setStaff] = useState({
    username: '',
    first_name: '',
    last_name: '',
    staff_nic: '',
    user_role: '',
    image: null,
    password: ""
  });
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastVariant, setToastVariant] = useState('');
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setStaff((prevStaff) => ({
      ...prevStaff,
      [name]: value
    }));
  };

  const handleFileChange = (e) => {
    setStaff((prevStaff) => ({
      ...prevStaff,
      image: e.target.files[0] // Set the selected file
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('staff', new Blob([JSON.stringify({
      username: staff.username,
      first_name: staff.first_name,
      last_name: staff.last_name,
      staff_nic: staff.staff_nic,
      user_role: staff.user_role,
      password: staff.password
    })], { type: "application/json" }));
    formData.append('image', staff.image); // Append the image file

    axios.post('https://project01-n0pn.onrender.com/api/staff/saveuser', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then((response) => {
      setToastMessage("Staff member added successfully");
      setToastVariant('success');
      setShowToast(true);
      setTimeout(() => {
        setShowToast(false); // Hide the toast after 5 seconds
        navigate("/home"); // Navigate to home after 5 seconds
      }, 5000);
    })
    .catch((error) => {
      setToastMessage("user already exist");
      setToastVariant('danger');
      setShowToast(true);
      console.error('Error adding staff member:', error);
    });
  };

  return (
    <Container>
      <Card className='card-container'>
        <h1 className='header'>Add New Staff Member</h1>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col>
              <FormGroup controlId="formUserName">
                <FormLabel>User Name</FormLabel>
                <FormControl
                  type="text"
                  name="username"
                  value={staff.username}
                  onChange={handleChange}
                  placeholder="Enter user name"
                  required
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup controlId="formFirstName">
                <FormLabel>First Name</FormLabel>
                <FormControl
                  type="text"
                  name="first_name"
                  value={staff.first_name}
                  onChange={handleChange}
                  placeholder="Enter first name"
                  required
                />
              </FormGroup>
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <FormGroup controlId="formLastName">
                <FormLabel>Last Name</FormLabel>
                <FormControl
                  type="text"
                  name="last_name"
                  value={staff.last_name}
                  onChange={handleChange}
                  placeholder="Enter last name"
                  required
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup controlId="forNIC">
                <FormLabel>NIC</FormLabel>
                <FormControl
                  type="text"
                  name="staff_nic"
                  value={staff.staff_nic}
                  onChange={handleChange}
                  placeholder="Enter NIC"
                  required
                />
              </FormGroup>
            </Col>
          </Row>
          <br/>
          <Row>
            <Col>
              <FormGroup controlId="formUserRole">
                <FormLabel>User Role</FormLabel>
                <FormControl
                  as="select"
                  name="user_role"
                  value={staff.user_role}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select user Role</option>
                  <option value="Doctor">Doctor</option>
                  <option value="Nurse">Nurse</option>
                  <option value="Admin">Admin</option>
                </FormControl>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup controlId="formImage">
                <FormLabel>Image</FormLabel>
                <FormControl
                  type="file"
                  name="image"
                  onChange={handleFileChange}
                  required
                />
              </FormGroup>
            </Col>
          </Row>
          <br/>
          <Row>
          <FormGroup controlId="forPassword">
                <FormLabel>Password</FormLabel>
                <FormControl
                  type="password"
                  name="password"
                  value={staff.password}
                  onChange={handleChange}
                  placeholder="Enter Password"
                  required
                />
              </FormGroup>
          </Row>
          <br/>
          <Button className="submit-button" variant="success" type="submit">
            Add Staff
          </Button>
        </Form>
      </Card>
      <ToastContainer position="top-end" className="p-3" style={{marginTop:"35px"}}>
        <Toast onClose={() => setShowToast(false)} show={showToast} className='toast' bg={toastVariant} delay={3000} autohide>
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>
    </Container>
  );
}
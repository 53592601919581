import React, { useEffect, useState } from "react";
import axios from "axios";
import { Card, CardBody, CardTitle, CardText, Container, Row, Col, Carousel } from "react-bootstrap";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { useLocation } from "react-router-dom";

export default function Home() {
  const [noOfDoctors, setNoOfDoctors] = useState(0);
  const [noOfPatients, setNoOfPatients] = useState(0);
  const [noOfAppointments, setNoOfAppointments] = useState(0);
  const [noOfStaff, setNoOfStaff] = useState(0);
  const [selectedDate, setSelectedDate] = useState(new Date()); // State to store selected date
  const [appointments, setAppointments] = useState([]); // State to store appointments
  const location = useLocation();
  const { userId } = location.state || {};

  useEffect(() => {
    axios.get('https://project01-n0pn.onrender.com/api/staff/noofdoctors').then((response) => {
      setNoOfDoctors(response.data);
    });
    axios.get('https://project01-n0pn.onrender.com/api/patient/noofpatients').then((response) => {
      setNoOfPatients(response.data);
    });
    axios.get('https://project01-n0pn.onrender.com/api/appointment/noofappointments').then((response) => {
      setNoOfAppointments(response.data);
    });
    axios.get('https://project01-n0pn.onrender.com/api/staff/noofstaff').then((response) => {
      setNoOfStaff(response.data);
    });
    axios.get('https://project01-n0pn.onrender.com/api/appointment/getallappointments').then((response) => {
      setAppointments(response.data);
    });
    if (userId) {
      // Perform any actions needed with the userId, e.g., fetching user-specific data
      console.log("User ID:", userId);
    }
    console.log("User ID:", userId);
  }, [userId]);

  return (
    <>
      <Carousel>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="https://www.withum.com/wp-content/uploads/2021/11/healthcare-symposium-web.gif"
            alt="First slide"
            style={{height: "500px"}}
          />
          <Carousel.Caption>
            <h5>Welcome To Our Healthcare Management System</h5>
            <p>Streamline healthcare operations with a comprehensive web-based management system.</p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
      <Container>
      <Row>
        <Col>
        <Card className="card-intro">
        <p>
        A healthcare management system serves as the backbone of modern medical facilities, seamlessly integrating essential functions to ensure efficient and effective healthcare delivery. 
        At its core, the system empowers healthcare providers to deliver personalized care while optimizing operational workflows.
        </p>
        <p> 
        With features like appointment management, patients can easily schedule and manage their visits, while healthcare professionals can efficiently organize their schedules and provide timely care. 
        Staff management tools streamline administrative tasks, from onboarding to scheduling, ensuring that every team member is equipped to deliver their best. 
        Patient management functionalities centralize patient data, enabling comprehensive care coordination and informed decision-making.
        </p>
        <p>
        Furthermore, checkup management features facilitate the seamless execution of medical procedures, ensuring that patients receive timely and appropriate care. 
        Through robust reporting and analytics capabilities, healthcare administrators gain valuable insights into patient demographics, resource utilization, and operational efficiency, driving continuous improvement and excellence in healthcare delivery. 
        Overall, a healthcare management system plays a pivotal role in enhancing patient outcomes, optimizing resource utilization, and fostering collaboration across the healthcare ecosystem.
        </p>
        </Card>
        </Col>
          <Col>
          <Row>
            <Col>
            <Card className="card-calender">
              <CardBody>
                <CardTitle style={{margin: "25px"}}>Appointments Calendar</CardTitle>
                <Calendar
                  onChange={setSelectedDate}
                  value={selectedDate}
                  tileContent={({ date }) => {
                    const appointmentsForDay = appointments.filter(appointment => {
                      const appointmentDate = new Date(appointment.appointment_date);
                      return appointmentDate.getDate() === date.getDate() &&
                             appointmentDate.getMonth() === date.getMonth() &&
                             appointmentDate.getFullYear() === date.getFullYear();
                    });
                    return appointmentsForDay.length > 0 && <div style={{backgroundColor:"#006064", color: "white"}}>{appointmentsForDay.length}</div>;
                  }}
                />
              </CardBody>
              <div style={{backgroundColor: "#006064", border: 1, color: "white", borderRadius: "25px"}}>No of Appointments</div>
            </Card>
            </Col>
            <Col></Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card className="card-home">
              <CardBody>
                <CardTitle>Total Available Staff</CardTitle>
                <CardText>{noOfStaff}</CardText>
              </CardBody>
            </Card>
          </Col>
          <Col>
            <Card className="card-home">
              <CardBody>
                <CardTitle>Total Available Doctors</CardTitle>
                <CardText>{noOfDoctors}</CardText>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card className="card-home">
              <CardBody>
                <CardTitle>Total Available Appointments</CardTitle>
                <CardText>{noOfAppointments}</CardText>
              </CardBody>
            </Card>
          </Col>
          <Col>
            <Card className="card-home">
              <CardBody>
                <CardTitle>Total Patients</CardTitle>
                <CardText>{noOfPatients}</CardText>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
import { Container, Nav, Row } from "react-bootstrap";
import './Footer.css'

export default function Footer(){
    return(
        <>
            <Container fluid className="footer">
                <Row className="p-4">
                <Nav.Link>&copy; 2024 Healthcare. All rights reserved.</Nav.Link>
                </Row>
            </Container>
        </>
    )
}
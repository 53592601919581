import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Card, Table, Toast, ToastContainer } from 'react-bootstrap';
import Pagination from '../../Components/Features/Pagination/Pagination';
import SearchBar from '../../Components/Features/SearchBar/SearchBar';
import './ViewAllStaff.css';
import { Link } from 'react-router-dom';
import moment from 'moment';

export default function ViewAllStaff() {
  const [staff, setStaff] = useState([]);
  const [filteredStaff, setFilteredStaff] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [staffPerPage] = useState(5);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastVariant, setToastVariant] = useState('');

  useEffect(() => {
    axios.get('https://project01-n0pn.onrender.com/api/staff/getallusers').then((response) => {
      setStaff(response.data);
      setFilteredStaff(response.data);  // Initialize filteredStaff with all staff
    });
  }, []);

  // Calculate the number of pages
  const totalPages = Math.ceil(filteredStaff.length / staffPerPage);

  // Get current staff data for the page
  const indexOfLastStaff = currentPage * staffPerPage;
  const indexOfFirstStaff = indexOfLastStaff - staffPerPage;
  const currentStaff = filteredStaff.slice(indexOfFirstStaff, indexOfLastStaff);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSearch = (query) => {
    setCurrentPage(1);  // Reset to first page on new search
    if (query) {
      const lowercasedQuery = query.toLowerCase();
      const filteredData = staff.filter(staffuser =>
        Object.values(staffuser).some(value =>
          value.toString().toLowerCase().includes(lowercasedQuery)
        )
      );
      setFilteredStaff(filteredData);
    } else {
      setFilteredStaff(staff);
    }
  };

  const handleDelete = (userId) => {
    // Make DELETE request to delete user with given userId
    axios.delete(`https://project01-n0pn.onrender.com/api/staff/deleteuser/${userId}`)
      .then((response) => {
        setToastMessage("Staff user deleted successfully");
        setToastVariant('danger');
        setShowToast(true);
        setStaff(staff.filter(staffuser => staffuser.user_id !== userId));
        setFilteredStaff(filteredStaff.filter(staffuser => staffuser.user_id !== userId));
      })
      .catch((error) => {
        setToastMessage('Error deleting user');
        setToastVariant('danger');
        setShowToast(true);
        console.error('Error deleting user:', error);
      });
  };

  const handleStatusToggle = (userId, currentStatus) => {
    const newStatus = currentStatus === 'Active' ? 'Deactive' : 'Active';
    axios.put(`https://project01-n0pn.onrender.com/api/staff/updateuserstatus/${userId}`, { staff_status: newStatus })
      .then(() => {
        // Update the status directly in the local state
        const updatedStaff = staff.map(staffuser => {
          if (staffuser.user_id === userId) {
            return { ...staffuser, staff_status: newStatus };
          }
          return staffuser;
        });
  
        setStaff(updatedStaff);
        setFilteredStaff(updatedStaff);
  
        setToastMessage(`Staff user status changed to ${newStatus}`);
        setToastVariant('primary');
        setShowToast(true);
      })
      .catch((error) => {
        setToastMessage('Error updating status');
        setToastVariant('primary');
        setShowToast(true);
        console.error('Error updating status:', error);
      });
  };  

  return (
    <>
      <Card className='card-container'>
        <h1 className="header">Staff Management</h1>
        <Button className="report-button" as={Link} to={'/report-staff'}>Generate Report</Button>
        <ToastContainer position="top-end" className="p-3">
        <Toast onClose={() => setShowToast(false)} show={showToast} className='toast' bg={toastVariant} delay={3000} autohide>
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>
        <SearchBar onSearch={handleSearch} />
        <Table responsive className="table">
          <thead>
            <tr>
              <th>NIC</th>
              <th>Username</th>
              <th>User Role</th>
              <th>Created At</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {currentStaff.map((staffuser) => (
              <tr key={staffuser.user_id}>
                <td>{staffuser.staff_nic}</td>
                <td>{staffuser.username}</td>
                <td>{staffuser.user_role}</td>
                <td>{moment(staffuser.createdAtStaff).format("MMMM Do YYYY, h:mm:ss a")}</td>
                <td>{staffuser.staff_status}</td>
                <td>
                  <Button variant="primary" onClick={() => handleStatusToggle(staffuser.user_id, staffuser.staff_status)}>
                    {staffuser.staff_status === 'Active' ? 'Deactive' : 'Active'}
                  </Button> {""}
                  <Button variant='secondary' as={Link} to={`/view-staff/${staffuser.user_id}`}>View</Button> {""}
                  <Button variant="warning" as={Link} to={`/update-staff/${staffuser.user_id}`}>Update</Button> {""}
                  <Button variant="danger" onClick={() => handleDelete(staffuser.user_id)}>Delete</Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </Card>
    </>
  );
}
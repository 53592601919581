/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table, Card, Button, Form, Row, Col } from 'react-bootstrap';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import Pagination from '../Features/Pagination/Pagination';

export default function StaffReport() {
  const [staff, setStaff] = useState([]);
  const [filteredStaff, setFilteredStaff] = useState([]);
  const [filters, setFilters] = useState({
    userRole: '',
    status: ''
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [staffPerPage] = useState(5);

  useEffect(() => {
    axios.get('https://project01-n0pn.onrender.com/api/staff/getallusers')
      .then((response) => {
        setStaff(response.data);
        setFilteredStaff(response.data);
      })
      .catch((error) => {
        console.error('Error fetching staff data:', error);
      });
  }, []);
  // Calculate the number of pages
  const totalPages = Math.ceil(filteredStaff.length / staffPerPage);

  // Get current staff data for the page
  const indexOfLastStaff = currentPage * staffPerPage;
  const indexOfFirstStaff = indexOfLastStaff - staffPerPage;
  const currentStaff = filteredStaff.slice(indexOfFirstStaff, indexOfLastStaff);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const downloadPDF = () => {
    const input = document.getElementById('staff-report');
    html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF();
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf.save('staff_report.pdf');
      })
      .catch((error) => {
        console.error('Error generating PDF:', error);
      });
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value
    });
  };

  const applyFilters = () => {
    let filteredData = staff;

    if (filters.userRole) {
      filteredData = filteredData.filter(staffuser => staffuser.user_role.toLowerCase().includes(filters.userRole.toLowerCase()));
    }

    if (filters.status) {
      filteredData = filteredData.filter(staffuser => staffuser.staff_status.toLowerCase().includes(filters.status.toLowerCase()));
    }

    setFilteredStaff(filteredData);
  };

  useEffect(() => {
    applyFilters();
  }, [filters]);

  return (
    <Card className='card-container'>
      <Card.Body>
        <Card.Title className='header'>Staff Report</Card.Title>
        <Form>
          <Row>
            <Col>
          <Form.Group controlId="filterUserRole">
            <Form.Control
              type="text"
              placeholder="Enter user role"
              name="userRole"
              value={filters.userRole}
              onChange={handleFilterChange}
            />
          </Form.Group>
          </Col>
          <Col>
          <Form.Group controlId="filterStatus">
            <Form.Control
              type="text"
              placeholder="Enter status"
              name="status"
              value={filters.status}
              onChange={handleFilterChange}
            />
          </Form.Group>
          </Col>
          </Row>
        </Form>
        <Button variant="primary" onClick={downloadPDF} className="mt-3">Download as PDF</Button>
        <div id="staff-report" className="mt-3">
          <Table responsive className="table">
            <thead>
              <tr>
                <th>Staff ID</th>
                <th>Staff NIC</th>
                <th>Staff Username</th>
                <th>Staff User Role</th>
                <th>Staff Created At</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {currentStaff.map((staffuser) => (
                <tr key={staffuser.user_id}>
                  <td>{staffuser.user_id}</td>
                  <td>{staffuser.staff_nic}</td>
                  <td>{staffuser.user_name}</td>
                  <td>{staffuser.user_role}</td>
                  <td>{new Date(staffuser.createdAtStaff).toLocaleString()}</td>
                  <td>{staffuser.staff_status}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
        </div>
      </Card.Body>
    </Card>
  );
}
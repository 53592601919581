import React from 'react';
import { Link } from 'react-router-dom';
import './Sidebar.css'; // Create a CSS file for styling the sidebar
import image from "../../Images/logo.png";

const Sidebar = () => {
    return (
        <div className="sidebar">
            <ul>
                <a href = "/">
            <img src={image} style={{width: "60%", marginBottom: "25px", marginTop: "25px", borderRadius: "25px"}} alt='logo'/>
            </a>
                <li><Link to="/view-all-staff">Staff Management</Link></li>
                <li><Link to="/view-all-patients">Patients Management</Link></li>
                <li><Link to="/view-all-appointments">Appointments Management</Link></li>
                <li><Link to="/view-all-checkups">Checkups Management</Link></li>
            </ul>
        </div>
    );
};

export default Sidebar;